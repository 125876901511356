@import '@ingka/variables/style';
.containerFilters{
    padding-top: 1rem;
    margin-top: 1rem;
    position: sticky;
    z-index: 101;
    top: 0;
    margin-left: -1rem;
    background-color: $colour-static-white;
    width: 100vw;
    max-width: 1300px;    
}

.modal_body{
    padding-bottom: 7rem;
}
    
.modal_header{
    border-bottom: $thickness-thin solid $colour-neutral-3;
}
.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.submit{
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 1.5rem 2.5rem;
    width: -webkit-fill-available;
    border-top: $thickness-thin solid $colour-neutral-3;
    button{
        width: 100%;
    }
}