@import '@ingka/variables/style';

.pairInputs{
  position: relative;
  .icon{
    color: $colour-static-dark-grey;
    position: absolute;
    padding: 0;
    left: 66.66%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}

.force{
  display: block;
  button{
    span{
      min-height: 1.6rem;
      svg{
        height: 1.1rem;
      }
    }
    
  }
  .isForce{
    >div{
      background-color: rgb(204, 0, 8, 0.1);
    }
  }
}


.difference{
  >div{
    background-color: rgb(255, 219, 0, 0.2);
  }
}