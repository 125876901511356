@import '@ingka/variables/style';

html{
    scroll-behavior: smooth;
}

body{
    margin: 0;
}

*{
    box-sizing: border-box;
}


.container-header, .container{
    width: 100%;
    margin: 0 auto;

    @media(min-width: 767px){
        padding: 0 1.5rem;
        max-width: 1500px;
    }
    @media(max-width: 767px){
        padding: 0 1rem;
    }
}

.container{
    margin: 0 auto;
}
.container-header{
    display: flex;
    align-items: center;
}

.container-header-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    button{
        margin-top: 1rem;
    }
}

hr{
    border-block-start: $thickness-thin solid $colour-neutral-3;
}
/* TEXT */
.text-center{
    text-align: center;
}
.text-red{
    color: $colour-commercial-message-bti-red;
}
.text-blue{
    color: $colour-static-ikea-brand-blue;
}
.text-white{
    color: $colour-static-white;
}
.text-right{
    text-align: right;
}

/*ROWS AND COLUMNS*/
.row{
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    margin-top: calc(-1 * 0);
    margin-right: calc(-.5 * 1.5rem);
    margin-left: calc(-.5 * 1.5rem);
    &>*{
        padding-right: calc(1.5rem * .5);
        padding-left: calc(1.5rem * .5);
        margin-top: 0;
    }
}
.col{
    flex: 1 0 0%;
}
.col-12{
    flex: 0 0 auto;
    width: 100%;
}
.col-8{
    flex: 0 0 auto;
    width: 66.66%;
}
.col-6{
    flex: 0 0 auto;
    width: 50%;
}
.col-4{
    flex: 0 0 auto;
    width: 33.33%;
}
.col-3{
    flex: 0 0 auto;
    width: 25%;
}
.col-2{
    flex: 0 0 auto;
    width: 20%;
}
@media(min-width: $breakpoint-l){
    .col-lg-12{
        flex: 0 0 auto;
        width: 100%;
    }
    .col-md-10{
        flex: 0 0 auto;
        width: 83.34%;
    }
    .col-lg-9{
        flex: 0 0 auto;
        width: 75%;
    }
    .col-lg-8{
        flex: 0 0 auto;
        width: 66.66%;
    }
    .col-lg-6{
        flex: 0 0 auto;
        width: 50%;
    }
    .col-lg-4{
        flex: 0 0 auto;
        width: 33.33%;
    }
    .col-lg-3{
        flex: 0 0 auto;
        width: 25%;
    }
    .col-lg-2{
        flex: 0 0 auto;
        width: 16.66%;
    }
}
@media(max-width: $breakpoint-l){
    .col-md-12{
        flex: 0 0 auto;
        width: 100%;
    }
    .col-md-10{
        flex: 0 0 auto;
        width: 83.34%;
    }
    .col-md-8{
        flex: 0 0 auto;
        width: 66.66%;
    }
    .col-md-6{
        flex: 0 0 auto;
        width: 50%;
    }
    .col-md-4{
        flex: 0 0 auto;
        width: 33.33%;
    }
    .col-md-3{
        flex: 0 0 auto;
        width: 25%;
    }
    .col-md-2{
        flex: 0 0 auto;
        width: 16.66%;
    }
}
@media(max-width: $breakpoint-m){
    .col-sm-12{
        flex: 0 0 auto;
        width: 100%;
    }
    .col-sm-8{
        flex: 0 0 auto;
        width: 66.66%;
    }
    .col-sm-6{
        flex: 0 0 auto;
        width: 50%;
    }
    .col-sm-4{
        flex: 0 0 auto;
        width: 33.33%;
    }
    .col-sm-3{
        flex: 0 0 auto;
        width: 25%;
    }
    .col-sm-2{
        flex: 0 0 auto;
        width: 16.66%;
    }
}


/* MARGINGS */
.m0{
    margin: 0 !important;
}
.m1{
    margin: 1rem !important;
}
.my-1{
    margin: 1rem 0 !important;
}
.mt-05{
    margin-top: 0.5rem;
}
.mt-1{
    margin-top: 1rem;
}
.mt-2{
    margin-top: 2rem;
}
.mt-3{
    margin-top: 3rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-5{
    margin-top: 5rem;
}
.ml-1{
    margin-left: 1rem;
}
.ml-2{
    margin-left: 2rem;
}
.ml-3{
    margin-left: 3rem;
}
.pt-1{
    padding-top: 1rem;
}
.pt-2{
    padding-top: 2rem;
}
.pt-3{
    padding-top: 3rem;
}
.mb-1{
    margin-bottom: 1rem;
}
.mb-2{
    margin-bottom: 2rem;
}
.mb-3{
    margin-bottom: 3rem;
}
.mb-0{
    margin-bottom: 0;
}
.mt-0{
    margin-top: 0;
}

.mx-a{
    margin: 0 auto;
}

/* WIDTHS */
.w-100{
    width: 100% !important;
}
.w-50{
    width: 50% !important;
}
.w-25{
    width: 25% !important;
}

/* MESSAGE CARD */
.inline-message .inline-message__actions .goToButton{
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
}
.inline-message .inline-message__content{
    padding-inline-end: 0rem; 
}


/* TOAST */
.toastsContainer{
    position: fixed;
    right: 2%;
    top: 2%;
    z-index: 9999;
    .inline-message{
        margin-bottom: 1rem;
    }
}



/* CAROUSEL BUTTONS */
.overflow-carousel__button--right{
    right: 0;
    .btn__inner{
        padding: 0 0.45rem !important;
        min-height: 2.4rem !important;
    }
}
#overflow-carousel__demo-prev-btn, #overflow-carousel__default-prev-btn{
    left: 0;
    .btn__inner{
        padding: 0 0.45rem !important;
        min-height: 2.4rem !important;
    }
}

//BACKGROUNDS
.bg-blue{
    background-color: $colour-static-ikea-brand-blue;
}
.bg-black{
    background-color: $colour-static-black;
}



//BUTTONS
.submit-buttons-bottom{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* JUSTIFY CONTENT */
.justify-content-end {
    display: flex;
    justify-content: flex-end;
}
.justify-content-center {
    display: flex;
    justify-content: center;
}

/*READ ONLY & DISABLED STYLES*/
.input-field--read-only .input-field__wrapper {
    background-color: rgb(0, 124, 193, 0.08);
}
.select-read-only{
    >div{
        background-color: rgb(0, 124, 193, 0.08);
        border: none
    }
    div[role=button]{
        display: none;
    }
}
.date-read-only{
    background-color: rgb(0, 124, 193, 0.08);
    border: none
}

.select-disabled{
    opacity: 0.4;
    div[aria-disabled="true"]{
        background-color: $colour-static-white;
    }
}
.text-area--read-only .text-area__wrapper textarea{
    background-color: rgb(0, 124, 193, 0.08);
    border: none;
}


/* ERROR ON INPUTS */
.inputAndIcons .input-field input{
    width: 90px;
}

/*ZOOMS*/
.zoom08{
    zoom: 0.8;
}
.zoom06{
    zoom: 0.6;
}
.zoom05{
    zoom: 0.5;
}
.zoom12{
    zoom: 1.2;
}
.zoom15{
    zoom: 1.5;
}
.zoom2{
    zoom: 2;
}
.zoom3{
    zoom: 3;
}