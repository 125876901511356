@import '@ingka/variables/style';

.list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  li{
    padding: 0.4rem 0.2rem;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
  }
}