@import '@ingka/variables/style';
.containerFilters{
    padding-top: 1rem;
    margin-top: 1rem;
    position: sticky;
    z-index: 2;
    top: 0;
    margin-left: -1rem;
    background-color: $colour-static-white;
    width: 100vw;
    max-width: 1500px;

    .filterButtons{
        @media(min-width: 767px){
            padding: 0 1.5rem;
            max-width: 1500px;
        }
        @media(max-width: 767px){
            padding: 0 1rem;
        }
    
        .filterButton{
            background-color: $colour-neutral-3;
            margin-left: 0.6rem;
        }
    }
    
    
}

.modal_body{
    padding-bottom: 7rem;

    .reset{
        
        width: 100%;
        text-align: end;
        button{
            color: $colour-static-ikea-brand-blue;
        }
    }
}
    
.modal_header{
    border-bottom: $thickness-thin solid $colour-neutral-3;
}
.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.radioState{
    fieldset{
        display: flex;
        > span{
            margin-right: 1.5rem;
            margin-bottom: 0;
            label{
                padding-inline-start: 0.3rem;
            }
        }
        
    }
}

.submit{
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 1.5rem 2.5rem;
    width: -webkit-fill-available;
    border-top: $thickness-thin solid $colour-neutral-3;
    button{
        width: 100%;
    }
}